import * as React from 'react';
import { graphql, PageProps } from 'gatsby';
import { PageVideosQuery } from '@generated/GraphqlTypes';
import Base from '@webLayouts/Base/Base';
import Videos from '@webPages/Misc/Videos';

const VideosPage: React.FC<PageProps<PageVideosQuery>> = ({ data }) => {
  const title = data?.videos?.frontmatter?.title ?? '';
  const seoTitle = data?.videos?.frontmatter?.seoTitle || title || undefined;
  const seoDescription =
    data?.videos?.frontmatter?.seoDescription ||
    data?.videos?.excerpt ||
    undefined;
  const body = data?.videos?.html ?? '';

  const videos = data?.yt?.edges
    ?.map(edge => ({
      id: edge?.node?.id ?? '',
      title: edge?.node?.title ?? '',
      description: edge?.node?.description ?? '',
      videoId: edge?.node?.videoId ?? '',
      date: edge?.node?.publishedAt ?? '',
      thumbnail: edge?.node?.thumbnail?.url ?? '',
    }))
    .sort((a, b) => {
      // sort by date ascending
      return new Date(b.date).getTime() - new Date(a.date).getTime();
    });

  return (
    <Base theme="light" title={seoTitle} description={seoDescription}>
      <Videos title={title} body={body} videos={videos} />
    </Base>
  );
};

export default VideosPage;

export const query = graphql`
  query PageVideos {
    videos: markdownRemark(
      fields: { collection: { eq: "pages" } }
      frontmatter: { page: { eq: "videos" } }
    ) {
      html
      excerpt
      frontmatter {
        title
        seoTitle
        seoDescription
      }
    }

    yt: allYoutubeVideo {
      edges {
        node {
          id
          title
          description
          videoId
          publishedAt
          privacyStatus
          channelTitle
          thumbnail {
            url
          }
        }
      }
    }
  }
`;
