import React from 'react';
import Box from '@webAtoms/Box/Box';
import Container from '@webAtoms/Container/Container';
import PageHeading from '@webMolecules/PageHeading/PageHeading';
import UpNext from '@webMolecules/UpNext/UpNext';
import * as Route from '@routes';
import * as styles from './videos.module.scss';

export interface VideosProps {
  title: string;
  body: string;
  videos: YoutubeVideoDetail[];
}

export interface YoutubeVideoDetail {
  id: string;
  title: string;
  description: string;
  videoId: string;
  date: string;
  thumbnail?: string;
}

const Videos: React.FC<VideosProps> = ({ title, body, videos }) => {
  const [curVideo, setCurVideo] = React.useState<YoutubeVideoDetail | null>(
    null
  );

  return (
    <>
      <Box>
        <PageHeading title={title}>
          <Box htmlContent={body} />
        </PageHeading>
        <Container>
          <Box className={styles.videos}>
            <Box className={styles.videosInner}>
              {videos.map(video => (
                <a
                  onClick={ev => {
                    ev.preventDefault();
                    setCurVideo(video);
                    return false;
                  }}
                  key={video.id}
                  className={styles.video}
                  title={video.title}
                  href={`https://www.youtube.com/watch?v=${video.videoId}`}
                  target="_blank"
                >
                  <Box className={styles.videoImage}>
                    <img
                      src={
                        video.thumbnail ??
                        `https://i.ytimg.com/vi_webp/${video.videoId}/maxresdefault.webp`
                      }
                    />
                    <span></span>
                  </Box>
                  <h3>{video.title}</h3>
                  <Box className={styles.videoCaption}>
                    <p>{video.description}</p>
                  </Box>
                </a>
              ))}
            </Box>
          </Box>
        </Container>

        <UpNext
          links={[
            { label: 'Journal', href: Route.journal() },
            { label: 'FAQ', href: Route.faqs() },
          ]}
        />
      </Box>

      {curVideo && (
        <Box className={styles.videoModal}>
          <button
            className={styles.videoModalBackdrop}
            onClick={() => {
              setCurVideo(null);
            }}
          />
          <Box className={styles.videoModalContent}>
            <Box className={styles.videoModalClose}>
              <button onClick={() => setCurVideo(null)}>&times;</button>
            </Box>
            <iframe
              width="560"
              height="315"
              src={`https://www.youtube.com/embed/${curVideo?.videoId}`}
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            />
          </Box>
        </Box>
      )}
    </>
  );
};

export default Videos;
